import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './views/HomeView.vue'
import CareersView from './views/CareersView.vue'
import SupportView from './views/SupportView.vue'
import PrivacyView from './views/PrivacyView.vue'
import AboutView from './views/AboutView.vue'
import ContactView from './views/ContactView.vue'

const routes = [
  { path: '/', component: HomeView, name: 'Home', navigation: 'main' },
  { path: '/careers', component: CareersView, name: 'Careers', navigation: 'main' },
  { path: '/support', component: SupportView, name: 'Support', navigation: 'main' },
  { path: '/privacy', component: PrivacyView, name: 'Privacy', navigation: 'footer' },
  { path: '/about', component: AboutView, name: 'About', navigation: 'main' },
  { path: '/contact', component: ContactView, name: 'Contact', navigation: 'main' },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router