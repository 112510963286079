<template>
  <div class="flex flex-col min-h-screen" :class="{ 'dark': isDarkMode }">
    <header class="bg-blue-950 dark:bg-blue-950 shadow-sm">
      <nav class="container mx-auto px-4 py-4 flex justify-between items-center">
        <router-link to="/" class="text-xl font-semibold text-white">Penpoint Consulting</router-link>
        
        <!-- Hamburger menu for small screens -->
        <button @click="toggleMenu" class="lg:hidden text-white">
          <font-awesome-icon icon="bars" />
        </button>
        
        <!-- Navigation for large screens -->
        <div class="hidden lg:flex items-center space-x-4">
          <button @click="toggleDarkMode" class="text-gray-300 hover:text-white transition-colors duration-200">
            <font-awesome-icon :icon="isDarkMode ? 'sun' : 'moon'" />
          </button>
          <router-link v-for="route in mainNavRoutes" :key="route.path" :to="route.path" class="text-gray-300 hover:text-white transition-colors duration-200">
            {{ route.name }}
          </router-link>
        </div>
      </nav>
      
      <!-- Mobile menu -->
      <div v-show="isMenuOpen" class="lg:hidden bg-blue-900">
        <div class="container mx-auto px-4 py-2 flex flex-col space-y-2">
          <button @click="toggleDarkMode" class="text-gray-300 hover:text-white transition-colors duration-200">
            <font-awesome-icon :icon="isDarkMode ? 'sun' : 'moon'" />
            {{ isDarkMode ? 'Light Mode' : 'Dark Mode' }}
          </button>
          <router-link v-for="route in mainNavRoutes" :key="route.path" :to="route.path" class="text-gray-300 hover:text-white transition-colors duration-200" @click="isMenuOpen = false">
            {{ route.name }}
          </router-link>
        </div>
      </div>
    </header>

    <main class="flex-grow container mx-auto px-4 py-8">
      <router-view></router-view>
    </main>

    <footer class="bg-blue-950 shadow-sm">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center text-white">
        <div>
          © {{ new Date().getFullYear() }} Penpoint Consulting. All rights reserved.
        </div>
        <div class="flex space-x-4">
          <router-link v-for="route in footerRoutes" :key="route.path" :to="route.path" class="text-gray-300 hover:text-white transition-colors duration-200">
            {{ route.name }}
          </router-link>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { RouterLink, RouterView, useRouter } from 'vue-router'

const router = useRouter()
const isMenuOpen = ref(false)

const mainNavRoutes = computed(() => 
  router.options.routes.filter(route => route.navigation === 'main')
)

const footerRoutes = computed(() => 
  router.options.routes.filter(route => route.navigation === 'footer')
)

const isDarkMode = ref(false)

const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value
  updateDarkMode()
}

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const updateDarkMode = () => {
  if (isDarkMode.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

onMounted(() => {
  isDarkMode.value = window.matchMedia('(prefers-color-scheme: dark)').matches
  updateDarkMode()

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
    isDarkMode.value = e.matches
    updateDarkMode()
  })
})
</script>