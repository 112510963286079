<template>
  <div>
    <h1 class="text-3xl font-semibold mb-4">Careers at Penpoint Consulting</h1>
    <p>Join our team of innovative professionals and help shape the future of consulting.</p>
    <ul class="list-disc list-inside">
      <li>Competitive salaries</li>
      <li>Flexible work environment</li>
      <li>Opportunities for growth and development</li>
      <li>Collaborative team culture</li>
    </ul>
    <p class="mt-4"><a href="mailto:careers@penpoint.dev">Email us! ✉️</a></p>
  </div>
</template>